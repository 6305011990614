import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { scrollToTop } from '../utils';

function Header() {
  const { t } = useTranslation();
  const location = useLocation();
  const [sidebar, setSidebar] = useState(false);

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  /*---------menu button event----------*/
  const handleSidebarMenu = () => {
    setSidebar((prev) => !prev);
  };

  const isSticky = () => {
    if (location.pathname !== '/') {
      return;
    }

    const header = document.querySelector('.header-area');
    const scrollTop = window.scrollY;

    if (header) {
      scrollTop >= 20
        ? header.classList.add('sticky')
        : header.classList.remove('sticky');
    }
  };

  return (
    <header
      className={`header-area style-3 ${
        location.pathname === '/' ? '' : 'sticky'
      }`}
    >
      <div className='header-logo'>
        <Link to='/'>
          <img alt='logo' width={150} src='/images/favicons/favicon.png' />
        </Link>
      </div>
      <div className={sidebar ? 'main-menu show-menu' : 'main-menu'}>
        <div className='mobile-logo-area d-lg-none d-flex justify-content-between align-items-center'>
          <div className='mobile-logo-wrap '>
            <Link to='/'>
              <img
                alt='logo'
                width={130}
                height={60}
                src='/images/favicons/favicon.png'
              />
            </Link>
          </div>

          <div className='menu-close-btn' onClick={handleSidebarMenu}>
            <i className='bi bi-x-lg' />
          </div>
        </div>

        <ul className='menu-list'>
          <li>
            <NavLink to='/' exact onClick={scrollToTop}>
              {t('home')}
            </NavLink>
          </li>

          <li>
            <NavLink to='/about' onClick={scrollToTop}>
              {t('about-dynamic')}
            </NavLink>
          </li>

          <li>
            <NavLink to='/solutions' onClick={scrollToTop}>
              {t('our-solutions')}
            </NavLink>
          </li>

          <li>
            <NavLink to='/clients' onClick={scrollToTop}>
              {t('clients')}
            </NavLink>
          </li>

          <li>
            <NavLink to='/contact' onClick={scrollToTop}>
              {t('contact-us')}
            </NavLink>
          </li>
        </ul>
      </div>
      <div className='nav-right d-flex align-items-center'>
        {/* <div className='hotline d-lg-flex d-none'>
          <div className='hotline-icon'>
            <img alt='honeImage' src='/images/icons/header-phone.svg' />
          </div>
          <div className='hotline-info'>
            <span>{t('click-to-call')}</span>
            <h6>
              <a href={`tel:${initPhoneNumber(CONFIG.phone)}`} dir='ltr'  rel="noreferrer" target='_blank'>
                {CONFIG.phone}
              </a>
            </h6>
          </div>
        </div> */}

        {/* <div className='eg-btn btn--primary3 header-btn'>
          <Link to='/login' onClick={scrollToTop}>
            {t('order-demo')}
          </Link>
        </div> */}

        <Link
          to='/contact#contact'
          className='eg-btn btn--primary3 btn--lg d-none d-md-inline-block'
        >
          طلب عرض تجريبي مجاني
        </Link>

        <div
          className='mobile-menu-btn d-lg-none d-block'
          onClick={handleSidebarMenu}
        >
          <i className='bx bx-menu' />
        </div>
      </div>
    </header>
  );
}

export default Header;
