import React, { Suspense, useLayoutEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import WOW from 'wowjs';
import BaseLayout from '../layout/BaseLayout';
import Preloader from '../layout/Preloader';

import './i18n';

import 'react-international-phone/style.css';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/scss/base.scss';

const About = React.lazy(() => import('../pages/About'));
const Clients = React.lazy(() => import('../pages/Clients'));
const Contact = React.lazy(() => import('../pages/Contact'));
const ErrorPage = React.lazy(() => import('../pages/ErrorPage'));
const HomePage = React.lazy(() => import('../pages/HomePage'));
const PrivacyAndPolicy = React.lazy(() => import('../pages/PrivacyAndPolicy'));
const Solutions = React.lazy(() => import('../pages/Solutions'));
const Dynamic = React.lazy(() => import('../pages/solutions/Dynamic'));
const ERPNextSolution = React.lazy(() => import('../pages/solutions/ERPNext'));
const NovaHR = React.lazy(() => import('../pages/solutions/NovaHR'));
const TermsAndConditions = React.lazy(() =>
  import('../pages/TermsAndConditions')
);
const Success = React.lazy(() => import('../pages/Success'));

function App() {
  useLayoutEffect(() => {
    // remove loading after content load
    window.onload = function () {
      document.body.classList.remove('loading');

      new WOW.WOW({
        live: false,
      }).init();
    };
  }, []);

  return (
    <Suspense fallback={<Preloader />}>
      <BrowserRouter>
        <BaseLayout>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/about' component={About} />
            <Route
              exact
              path='/success-6cb668ef1ac55754e8472e1f3781212683a21e28920956300cbf974a9c4432be'
              component={Success}
            />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/clients' component={Clients} />
            <Route exact path='/solutions' component={Solutions} />
            <Route
              exact
              path='/solution-details/erp-next'
              component={ERPNextSolution}
            />
            <Route exact path='/solution-details/nova-hr' component={NovaHR} />
            <Route exact path='/solution-details/dynamic' component={Dynamic} />
            <Route
              exact
              path='/terms-conditions'
              component={TermsAndConditions}
            />
            <Route exact path='/privacy-policy' component={PrivacyAndPolicy} />
            <Route path='*' component={ErrorPage} />
          </Switch>
        </BaseLayout>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
